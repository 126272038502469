import React from "react";
// import Youtube from "emersonbroga-youtube-embed";
// import { FacebookProvider, Comments } from "react-facebook";

import SEO from "src/components/SEO";
import { NAME, BASE_URL } from "src/helpers/config";
import { redirectTo } from "src/helpers/route";

// import { PROJECT_DEV_2021_DATES as dates } from "src/helpers/config";
// import { isGTNow } from "src/helpers/datetime";

// import Header from "../components/Header";
// import Schedule from "../components/Schedule";

import "../projeto-dev-2021.scss";

const Day2 = ({ location }) => {
  const seoProps = {
    title: `Projeto Dev 2021 - Dia 2 - ${NAME}`,
    description: "Já estamos no segundo dia do Projeto Dev 2021, participe.",
    canonical: `${BASE_URL}/dev-2021/day-2/`,
    image: `${BASE_URL}/meta/og-image-projeto-dev-2021.jpg`,
  };

  redirectTo("/react");

  return (
    <div className="projeto-dev-2021">
      <SEO {...seoProps} />
      <div className="wrapper">
        <p>Redirecionando...</p>
      </div>
    </div>
  );
  /*
  if (!isGTNow(dates[1].start)) {
    return (
      <div className="projeto-dev-2021">
        <SEO {...seoProps} />
        <Header />
        <Schedule location={location} />
        <div className="wrapper">
          <div className="day1">
            <h2>Aguarde...</h2>
            <p>Esse video será liberado no dia 27 de Janeiro às 8:00h</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="projeto-dev-2021">
      <SEO {...seoProps} />
      <Header />
      <Schedule location={location} />
      <div className="wrapper">
        <div className="day1">
          <h2>Se torne um Dev em 2021</h2>
          <Youtube videoId="J3opEWHXo-c" />
        </div>
      </div>

      <div className="facebook wrapper">
        <p>
          Dev é a profissão que você quer serguir? Deixe aí nos comentários:
        </p>
        <FacebookProvider appId={FACEBOOK_APP_ID}>
          <Comments href={`${BASE_URL}/dev-2021/day-2`} width="100%" />
        </FacebookProvider>
      </div>
    </div>
  );
  */
};
export default Day2;
